.headerParentDiv {
  padding: 8px 20px;
  position: relative;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
}

.logo {
  position: absolute;
  left: 12px;
  cursor: pointer;
}

.productSearch {
  width: 36em;
  height: 48px;
  border-radius: 4px;
}

.right-container-position {
  display: flex;
  position: absolute;
  gap: 8px;
  right: 12px;
  align-items: center;
}

.right-container {
  display: flex;
  gap: 8px;
}

@media only screen and (max-width: 1050px) {
  .welcome-name {
    font-size: small;
  }
}

@media only screen and (max-width: 1020px) {
  .placeSearch,
  .productSearch {
    display: none;
  }
}