.footerParentDiv {
  width: 100%;
}

.footerParentDiv .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-left: 3em;
}
.footerParentDiv .content .heading {
  font-size: 24px;
  font-weight: bolder;
}

.footerParentDiv .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #0b5ed7;
  color: white;
}

.footerParentDiv .footer p {
  margin: 0;
}

.footer ul {
  margin: 0;
  display: flex;
  list-style-type: none;
}

.footer li {
  text-align: center;
  font-weight: 200;
  margin: 0 3px;
}

.footer a {
  color: white;
}

.footer a:hover {
  opacity: 0.5;
}

@media only screen and (max-width: 720px) {
  .social-media {
    display: none;
  }
}
