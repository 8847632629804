#root {
  margin: 0;
  overflow-x: hidden;
}

.dropdown-toggle-hide-arrow::after {
  display: none !important; 
}

.hidden-scroll{
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.hidden-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}