.container-message {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;  
  background-color: #dfdfe069;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
}

.input-container {
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container-message-box {
  overflow-x: hidden;
  max-height: 85%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

}

.container-name {
  top: 0;
  position: absolute;
  padding-top: 0.5em;
  padding-left: 1.5em;
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.5);
  background-color: white;
  width: 100%;
  color: #0b5ed7;
}
