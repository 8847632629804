.image-card {
  margin: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
  max-width: 100%;
  background-color: #ededed;
}

.carousel-content {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-content-image {
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: min-content;
}

.carousel-image-size {
  max-width: 100%;
  object-fit: contain;
  height: 20em;
} 

@media only screen and (max-width: 920px) {
  .image-card {
    min-width: 100%;
    margin: 0;
  }
}
