.container-chat {
  width: 100%;
  height: 37em;
  display: flex;
}

/* The property of ChatItem was been changed by some other not find css property, 
to avoid this for now, we reset the property by his css class. */
.rce-citem-body--bottom-status span, .rce-citem-body--bottom-status-icon {
  background-color: rgb(254, 66, 66);
}