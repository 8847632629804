@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.root-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.form_container {
  width: 18.5em;
  padding: 3rem;
  border-radius: 1rem;
  background-color: white;
}

.bg-custom {
  background-color: #4caf50;
}

.custom-link {
  color: #4caf50;
}

.custom-link:hover {
  text-decoration: underline;
}

body {
  font-family: "Poppins", sans-serif;
}
