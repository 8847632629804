@import url('https://fonts.googleapis.com/css2?family=Texturina:ital,opsz,wght@0,12..72,100..900;1,12..72,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

.bannerParentDiv {
  padding-top: 4em;
  width: 100%;
  margin-bottom: 0.5em;
}

.menuBar {
  background-color: #0b5ed7;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
}

.bannerParentDiv {
  display: flex;
  flex-direction: column;
}

.bannerParentDiv .categoryMenu a {
  margin-right: 16px;
  font-weight: bolder;
}

.otherQuickOptions {
  text-align: center;
  width: 100%;
}

.otherQuickOptions a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

.otherQuickOptions a:hover {
  font-size: 16px;
}

.bannerParentDiv .menuBar a {
  padding: 10px;
}

.carousel-item {
  width: 100%;
  position: relative;
}

.carousel-caption {
  font-weight: 700;
  font-size: 22px;
  font-family: "Exo 2", sans-serif;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(90, 135, 94, 0.348);
  display: flex;
  flex-direction: column;
  justify-content: center ;
}

.banner-header {
  height: 20em;
  width: 100%;
  padding: 0;
} 

.banner-image {
  width: 100%;
  height: 20em;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .otherQuickOptions {
    display: none;
  }
  .bannerParentDiv {
    padding: 0;
  }
  .banner-header {
    margin-top: 2em;
  }
}
