.container-create {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100dvh;
  background: linear-gradient(to right, #7fd082, #7ab67e, #4caf50);
  padding-top: 4em;
}

.container-form {
  display: flex;
  flex-direction: column;
  width: 70em;
  padding: 1.5em;
  border-radius: 1em;
  border: 1px solid rgb(214, 214, 214);
  color: #0b5ed7;
  background-color: white;
}

.container-form h2 {
  text-align: center;
  color: black;
}

@media only screen and (max-width: 280px) {
  .container-create {
    padding: 5em 0 1em 0;
  }
}
