.root-info {
  width: 100%;
  height: fit-content;
  background-color: #4caf50;
  opacity: 0.8;
  padding: 3em;
  color: white;
}

.gradient-transition-top {
  margin-top: 1em;
  background: linear-gradient(to right, #4caf50, #5dfc62);
  width: 100%;
  height: 4px;
}

.gradient-transition-bottom {
  height: 4px;
  background: linear-gradient(to right, #4caf50, #0b5ed7);
  width: 100%;
}

.container-info-one {
  padding: 0 5em;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.container-info-two {
  padding: 0 5em;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.container-info-two span {
  font-weight: 400;
}

.container-info-three {
  position: relative;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-right: 5em;
}

.leaf-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
  width: 20em;
  height: 20em;
}

.climate-container {
  clip-path: circle(45% at 50% 50%);
}

.people-container {
  position: relative;
  left: 8.7em;
  clip-path: circle(32% at 29% 50%);
}

.info-one-description {
  width: 65%;
  font-size: large;
  font-weight: 300;
  line-height: 36px;
}

@media only screen and (max-width: 1080px) {
  .container-info-three,
  .container-info-one,
  .container-info-two {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin: 3em 0;
  }
  .container-info-three {
    left: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .container-info-three,
  .container-info-one,
  .container-info-two {
    padding: 0;
  }
  .container-info-three {
    padding-right: 5em;
  }
}