.container-all-parent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100%;
}

.product-not-found-view {
  width: 100%;
  height: 100%;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: rgb(90, 90, 90);
}

.display-all-parent {
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 320px);  
  justify-content: center;  
  padding-top: 6em;
  padding-bottom: 1em;
}