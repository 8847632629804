.search-property-container {
  position: relative;
}

.searchInputs {
  position: absolute;
  height: 48px;
  display: flex;
}

.search-property-container input {
  z-index: 1;
  padding-left: 20px;
  padding-right: 40px;
  width: 30em;
  height: 48px;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  background-color: white;
  border-color: #dcddde;
  display: flex;
  align-items: center;
}

.search-property-container .searchIcon {
  position: absolute;
  top: 4px;
  left: 0;
  width: 33em;
  height: 3em;
  border-radius: 2rem;
  background-color:   #4caf50;
  place-items: center;
  color: black;
}

.searchIcon {
  position: relative;
}

.search-icon-div {
  position: absolute;
  top: 9px;
  left: 30.2em;
}

.search-property {
  cursor: pointer;
  color: white;
}

.search-property:hover {
  opacity: 0.5;
}


input:focus {
  outline: #4caf50;
}

.dataResult {
  position: absolute;
  top: 3.6em;
  width: 33em;
  height: 200px;
  background-color: white;
  box-shadow: #00000059 0px 5px 15px;
  border-radius: 8px;
  overflow: hidden;
  overflow-y: 20em;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: #0b5ed7;
}

.dataItem p {
  margin-left: 10px;
}

.dataResult:hover {
  background-color: #ebeeef;
}

#clearBtn {
  cursor: pointer;
}
