.viewParentDiv {
  width: 100%;
  padding-top: 4em;
  display: flex;
  flex-direction: column;
}
.rightSection {
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  font-size: 18px;
}

.info-product {
  display: flex;
  gap: 1em;
}

.contactDetails {
  border: 0.5px solid rgb(214, 214, 214);
  border-radius: 1em;
  padding: 1em;
  width: 100%;
}

.contactDetails span {
  color: #0b5ed7;
  font-weight: 500;
}

.info-seller {
  display: flex;
  justify-content: space-between;
}

.productDetails {
  display: flex;
  flex-direction: column;
  border: 0.5px solid rgb(214, 214, 214);
  border-radius: 1em;
  padding: 1em;
  background-color: #0b5ed7;
  color: white;
  font-weight: 500;
  min-width: 30%;
}

.productDetails p {
  font-size: 30px;
  font-weight: 600;
}

.productDescription {
  border: 0.5px solid rgb(214, 214, 214);
  border-radius: 1em;
  padding: 1em;
  width: 100%;
}

.productDescription span {
  font-weight: 500;
  color: #0b5ed7;
}

.description-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-mode {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0b5dd7e2, #4782db, #0b5dd7e2);
  z-index: 100;
  display: flex;
  justify-content: center;
  top: 0;
  align-items: center;
}

#button-info-mobile {
  display: none;
}

@media only screen and (max-width: 1120px) {
  #button-info-desktop {
    display: none;
  }
  #button-info-mobile {
    display: flex;
    margin-left: auto;
  }
}

@media only screen and (max-width: 920px) {
  .viewParentDiv {
    flex-direction: column;
  }
  .rightSection {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .description-info,
  .info-product {
    flex-direction: column;
  }
  .description-info {
    align-items: start;
  }
  .rightSection {
    width: 100%;
  }
  #button-info-mobile {
    width: 100%;
  }
}
