.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100%;
}

.info-email-unifor a {
  margin-left: 5px;
  font-size: 12px;
}

.logo-signup {
  display: flex;
  margin-bottom: 20px;
  gap: 35px ;
  align-items: center;
  justify-content: center;
}

.signupParentDiv {
  border: 1px solid rgb(168, 174, 255);
  width: 25em;
  padding: 2em;
  border-radius: 1em;
  background-color: white;
  color: #0b5ed7;
}

.signupParentDiv h3 {
  color: #0b5ed7;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .signupParentDiv {
    border: none;
  }
}