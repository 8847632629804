.pendind-card {
  opacity: 0.4;
}

.btn-delete {
  border-top-right-radius: 50%;
}

.delete-card {
  position: absolute;
  top: 0;
  right: 0;
}

.card-text-p {
  font-size: 14px;
}