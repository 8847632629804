.postParentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
  gap: 20px;
}

.container {
  padding: 1em 1em 0.5em 1em  ;
  border-radius: 8px;
  border: 0.5px solid rgb(214, 214, 214);
}

.container-section {
  width: 100%;
}

.container-section h4 {
  color: rgb(57, 57, 57);
  text-shadow: 1px 1px 1px white;
  font-weight: 500;
  margin-left: 5em;
  color: #0b5ed7;
}

.container .cards {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cards {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-right: 5em;
}

.header-container span {
  font-size: 14px;
}

.container .heading span {
  font-size: 24px;
}

.fresh-recomendation-cards {
  display: flex;
  flex: wrap;
  justify-content: space-evenly;
  margin-bottom: 1em;
}

.product-not-found {
  margin-left: 3em;
  margin-right: 3em;
  font-size: 24px;
  text-align: center;
  color: rgb(148, 148, 148);
  border: 0.5px solid rgb(214, 214, 214);
  padding: 2em;
  border-radius: 1rem;
}

@media only screen and (min-width: 1090px) and (max-width: 1300px) {
  .container-section h4 {
    margin-left: 3em;
  }
}

@media only screen and (max-width: 1050px) {
  .welcome-name {
    font-size: small;
  }
}

@media only screen and (max-width: 1200px) {
  .container-section h4 {
    text-align: center;
    margin-left: 0;
  }
}

@media only screen and (max-width: 520px) {
  .fresh-recomendation-cards {
    padding: 0em 3em;
  }
  .product-not-found {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 16px;
  }
  .product-not-found p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .fresh-recomendation-cards {
    padding: 0 0.5em;
  }
}
