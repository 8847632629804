.chat-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  position: relative;
  position: fixed;
  border-radius: 100%;
  background-color: #5fdc63;
  bottom: 20px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.notification-alert {
  z-index: 999999999;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(254, 66, 66);
  width: 19px;
  height: 19px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.notification-alert p {
  color: white;
  font-size: x-small;
  padding: 2.5px;
}