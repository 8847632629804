.pagination-items-position {
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 20px;
}

.not-active {
  border: 2px solid rgba(223, 226, 223, 0.67);
  width: 25px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
}

.active-pagination {
  font-weight: bold;
  color: white;
  background-color: rgba(54, 223, 54, 0.67);
  border-radius: 100%;
  width: 25px;
  display: flex;
  justify-content: center;
}