.login-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 50px;
  margin: 10px 8px 8px 8px;
}

.title-modal {
  color: #0b5ed7;
  font-weight: 700;
}

.modal-header {
  padding-left: 25px;
}

.label-modal {
  color: #0b5ed7;
  font-weight: 500;
}

.input-active {
  color: #0b5ed7;
}

.btn-modal {
  display: flex;
  gap: 5px;
  justify-content: end;
  width: 100%;
  align-items: center;
}
