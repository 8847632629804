.container-info {
  padding-top: 5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
  background-color: #eff3f5;
}

.info-user {
  width: 100%;
  height: 12em;
  border: 1px solid #ebeeef;
  border-radius: 1rem;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 0.5em 2em;
  gap: 5em;
  display: flex;
  align-items: center;
}

.info-user-style {
  display: flex;
  gap: 25px;
  align-items: center;
}

.user-details span {
 font-size: 18px;
 font-weight: 600;
 color: #0b5ed7;
}

@media only screen and (max-width: 650px) {
  .user-circle-icon {
    display: none;
  }
}

@media only screen and (max-width: 390px) {
  .info-user {
    padding: 0.5em 1em;
  }
  .container-info {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .user-details {
    font-size: 14px;
  }
  .user-details span {
    font-size: 16px;
   }
}