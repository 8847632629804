.loading-position {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.leaf-position {
  position: absolute;
  top: 22px;
  color: #38ed3e;
}