.content {
  padding: 0 0.5em 0.2em 0.5em;
}

.content strong {
  color: #4caf50;
}

.content a {
  font-weight: 500;
  cursor: pointer;
}

.user-loged {
  color: #ffca2c;
}