.title {
  color: rgb(211, 20, 20);
}

.btn-confirm {
  display: flex;
  justify-content: end;
  padding: 0.5em;
}

.option-description {
  color: gray;
}